<template>
  <div class="text-center">
    <v-dialog v-model="showUpdateModel" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Pi Ad</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-radio-group grow v-model="editPiAdsForm.ad_source">
                      <v-radio label="Is Qureka Ad*" :value="'qurekaad'"></v-radio>
                      <v-radio label="Is IGaming Ad*" :value="'igaminad'"></v-radio>
                      <v-radio label="Is Our Ad*" :value="'ourad'"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12"> 
                  <v-text-field label="App Name *" :rules="[v => !!v || 'App Name is required']" v-model="editPiAdsForm.appName" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field :label="showAppDetail ? 'App Store URL *' : 'App URL *' " :rules="[v => !!v || 'App Store URL is required']" v-model="editPiAdsForm.appStoreURL" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    label="Banner *"
                    dense
                    v-model="editPiAdsForm.banner"
                    prepend-icon="mdi-camera"
                    :rules="[v => !!v || 'Banner is required',v => (v && v.size > 0) || 'Banner is required']"
                    ref="bannerFileUpload"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="['banner', 'fullscreen', 'native', 'coin']"
                    label="Ads Type *"
                    v-model="editPiAdsForm.adsType"
                    :rules="[v => !!v || 'Ads Type is required']"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-checkbox label="Active *" v-model="editPiAdsForm.isActive"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetUpdatePiAdsModel()">
            Close
          </v-btn>
          <v-btn color="primary" @click="updatePiAds()">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from '../Snackbar.vue';
export default {
  name: "UpdatePiAd",
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    piAdDetail: {
      type: Object,
    },
    imageBase:{
      type: String
    }
  },
  components: {
    Snackbar,
  },
  data: () => ({
    showUpdateModel: false,
    editPiAdsForm:{
      appName:'',
      isActive:false,
      appStoreURL: '',
      adsType:'',
      isQurekaAd:false,
      banner:[],
      ad_source: ''
    },
    showAppDetail:true,
    duplicateEditForm: null,
    i : 1,
    valid: true,
  }),
  watch: {
    showModel(val) {
      this.showUpdateModel = val;
    },
    'editPiAdsForm.ad_source': function (value) {  
      if (value == "qurekaad") {
        this.editPiAdsForm.appName = 'qurekaad';
        this.editPiAdsForm.ad_source = 'qurekaad'
        this.showAppDetail = false;
      }
      if (value == "igaminad") {
        this.editPiAdsForm.ad_source = 'igaminad'
        this.editPiAdsForm.appStoreURL = '';
        this.showAppDetail = false;
      }
      if (value == "ourad") {
        this.editPiAdsForm.ad_source = 'ourad'
        this.editPiAdsForm.appStoreURL = '';
        this.showAppDetail = true
      }
      if (this.editPiAdsForm.appName == 'qurekaad' || this.editPiAdsForm.ad_source == 'igaminad') {
        this.editPiAdsForm.appStoreURL = this.duplicateEditForm.appStoreURL ? this.duplicateEditForm.appStoreURL : 'https://play.google.com/store/apps/details?id=';
      }
      this.$refs.form.resetValidation();
    },
    'editPiAdsForm.appName':function(value) {
      if (value) {
        this.editPiAdsForm.appName = value;
      }
      if (this.editPiAdsForm.ad_source == 'qurekaad' || this.editPiAdsForm.ad_source == 'igaminad') {
        this.editPiAdsForm.appStoreURL = '';
      } else {
        this.editPiAdsForm.appStoreURL = this.duplicateEditForm.appStoreURL.length <= 0 ? 'https://play.google.com/store/apps/details?id='+value : this.duplicateEditForm.appStoreURL;
      }
    },
    async piAdDetail(val) {
      this.editPiAdsForm = val;
      if(this.editPiAdsForm.bannerURL){
        var bannerimageUrl = this.imageBase + this.editPiAdsForm.bannerURL;
        var imgName =  bannerimageUrl.split(/[#?]/)[0].split("/").pop().trim();
        await fetch(bannerimageUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], imgName, { contentType });
          this.setFileInput(file);
        })
      }
    },
  },
  computed: {},
  mounted: function () {},
  created: function () {
    this.duplicateEditForm = this.editPiAdsForm
  },
  methods: {
    resetUpdatePiAdsModel(){
        this.$emit('close');
    },
    updatePiAds(){
      if(this.$refs.form.validate()){
        let formData = new FormData();
        formData.append('appName', this.editPiAdsForm.appName);
        formData.append('isActive', this.editPiAdsForm.isActive);
        formData.append('appStoreURL', this.editPiAdsForm.appStoreURL);
        formData.append('adsType', this.editPiAdsForm.adsType);
        formData.append('isQurekaAd', this.editPiAdsForm.isQurekaAd);
        formData.append('banner', this.editPiAdsForm.banner);
        formData.append('ad_source', this.editPiAdsForm.ad_source);
        formData.append('_id', this.editPiAdsForm._id);
        axios.put(this.Node_JS_HOST + "/api/v1/web/pi-ads",formData,{
          headers: {'content-type': 'multipart/form-data'},
        })
        .then((res) => {
            if(res){
                this.resetUpdatePiAdsModel();
                this.snackbarText = 'PI Ad Updated.';
                this.isShowSnackbar = true;
            }
        })
        .catch((error) => {
            var errorType = error.response.data.error;
            this.errorHandle(error);
            if(errorType === 'Not Acceptable'){
              this.snackbarText = 'Please insert AppName';
              this.isShowSnackbar = true;
            }else{
              this.snackbarText = 'Please insert valid input';
              this.isShowSnackbar = true;
            }
        });
      }
    },
    setFileInput(file){
      this.editPiAdsForm.banner = file;
      this.$refs.bannerFileUpload.value = file;
      console.clear();
    },
  },
};
</script>