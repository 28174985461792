<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="mb-7">
          <v-toolbar flat>
            <v-toolbar-title> PI Ads </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="addPiAdsModal = true"
              class="mx-2"
              fab
              dark
              small
              color="#1976d2"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-data-table
              class="mx-5"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :search="search"
              :items="allPiAds"
              :footer-props="{
                'items-per-page-options': [50, 100, 150, 200, 300, -1]
              }"
              :items-per-page="50"
              :custom-filter="filterOnlyCapsText"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Search (PI Ads Details)"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item="row">
                <tr>
                  <td>{{ row.item.appName }}</td>
                  <td>
                    <a :href="imageBase + row.item.bannerURL" target="_blank" style="text-decoration: none;"><v-icon color="primary">mdi-eye</v-icon> View</a>
                  </td>
                  <td>{{ row.item.adsType }}</td>
                  <td style="max-width:300px">
                    <a :href="row.item.appStoreURL" target="_blank" style="text-decoration: none;"><v-icon color="primary">mdi-eye</v-icon> View</a>
                  </td>
                  <td>
                    <v-chip x-small label :color="getColor(row.item.isActive)" dark>
                      {{ row.item.isActive }}
                    </v-chip>
                  </td>
                  <td>{{ dateFormat(row.item.createdon) }}</td>
                  <td><a @click="UpdatePiAdModel(row.item)"><v-icon color="primary">mdi-pencil</v-icon></a> | <a @click="showDeleteDilog(row.item)"><v-icon color="red">mdi-delete</v-icon></a></td>
                </tr>
              </template>
              <template v-slot:no-results>NO RESULTS HERE</template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="addPiAdsModal" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add PI Ad</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" class="py-0">
                    <v-radio-group grow v-model="addPiAdForm.ad_source">
                      <v-radio label="Is Qureka Ad*" :value="'qurekaad'"></v-radio>
                      <v-radio label="Is IGaming Ad*" :value="'igaminad'"></v-radio>
                      <v-radio label="Is Our Ad*" :value="'ourad'"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="App Name *" :rules="[v => !!v || 'App Name is required']" v-model="addPiAdForm.appName" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field :label="showAppDetail ? 'App Store URL *' : 'App URL *' " :rules="[v => !!v || 'App Store URL is required']" v-model="addPiAdForm.appStoreURL" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    label="Banner *"
                    dense
                    :rules="[v => !!v || 'Banner is required',v => (v && v.size > 0) || 'Banner is required']"
                    v-model="addPiAdForm.banner"
                    prepend-icon="mdi-camera"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="['banner', 'fullscreen', 'native', 'coin']"
                    label="Ads Type *"
                    v-model="addPiAdForm.adsType"
                    :rules="[v => !!v || 'Ads Type is required']"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-checkbox label="Active *" v-model="addPiAdForm.isActive"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetAddPiAdModel()">
            Close
          </v-btn>
          <v-btn color="primary" @click="savePiAds()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
    <delete-dialog :contentText="deleteText" :showModel="showDeleteModel" :id="deleteId" :headerText="'Delete Pi Ad'" @action="deleteAction"></delete-dialog>
    <update-pi-ad :showModel="showUpdatePiAdModel" :pi-ad-detail="updatePiAdsDetail" :image-base="imageBase" @close="updatePiAd" ></update-pi-ad>
  </v-container>
</template>
<script>
import axios from "axios";
import UpdatePiAd from './UpdatePiAd.vue';
import DeleteDialog from '../DeleteDialog.vue';
import Snackbar from '../Snackbar.vue';
export default {
  name: "PiAds",
  components: {
    DeleteDialog,
    Snackbar,
    UpdatePiAd,
  },
  data: () => ({
    search: "",
    calories: "",
    allPiAds: [],
    addPiAdsModal: false,
    headers: [
      { text: "App Name", value: "appName" },
      { text: "Banner URL", value: "bannerURL" },
      { text: "Ads Type", value: "adsType" },
      { text: "AppStore URL", value: "appStoreURL" },
      { text: "Is Active", value: "isActive" },
      { text: "Created At", value: "createdon" },
      { text: "Action" },
    ],
    imageBase: "",
    tableLoading: true,
    addPiAdForm:{
      appName:'',
      isActive: true,
      appStoreURL: 'https://play.google.com/store/apps/details?id=',
      adsType:'',
      isQurekaAd:false,
      banner: [],
      ad_source: ''
    },
    showAppDetail:true,
    valid: true,
    deleteText:'',
    showDeleteModel:false,
    deleteId:'',
    showUpdatePiAdModel:false,
    updatePiAdsDetail:{}
  }),
  computed: {},
  mounted: function () {
    this.getPiAds();
  },
  watch: {
    'addPiAdForm.ad_source': function (value) {    
      if (value == "qurekaad" || value == "igaminad") {
        this.addPiAdForm.appName = value == "qurekaad" ? 'qurekaad' : '';
        this.addPiAdForm.appStoreURL = '';
        this.showAppDetail = false;
      }
      if (value == "ourad") {
        this.addPiAdForm.appName = '';
        this.showAppDetail = true
      }
      this.$refs.form.resetValidation();
    },
    'addPiAdForm.appName':function (value) {
      if (this.addPiAdForm.ad_source == 'qurekaad' || this.addPiAdForm.ad_source == 'igaminad') {
        this.addPiAdForm.appStoreURL = '';
      } else {
        this.addPiAdForm.appStoreURL = 'https://play.google.com/store/apps/details?id='+value;
      }
    }
  },
  created: function () {},
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getPiAds() {
      this.tableLoading = true;
      axios.get(this.Node_JS_HOST + "/api/v1/web/pi-ads")
        .then((res) => {
          const response = res.data;
          this.allPiAds = response.data.banners;
          this.imageBase = response.data.imageBase;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.errorHandle(error);
          this.tableLoading = false;
        });
    },
    getColor(isActive) {
      if (isActive) return "green";
      else return "orange";
    },
    resetAddPiAdModel(){
      this.addPiAdForm.appName = '';
      this.addPiAdForm.isActive = true;
      this.addPiAdForm.appStoreURL = '';
      this.addPiAdForm.banner = [];
      this.addPiAdForm.adsType = '';
      this.addPiAdForm.isQurekaAd = false;
      this.addPiAdsModal = false;
      this.addPiAdForm.ad_source = ''
      this.$refs.form.resetValidation();
    },
    savePiAds(){
      if(this.$refs.form.validate()){
        let formData = new FormData();
        formData.append('appName', this.addPiAdForm.appName);
        formData.append('isActive', this.addPiAdForm.isActive);
        formData.append('appStoreURL', this.addPiAdForm.appStoreURL);
        formData.append('adsType', this.addPiAdForm.adsType);
        formData.append('isQurekaAd', this.addPiAdForm.isQurekaAd);
        formData.append('banner', this.addPiAdForm.banner);
        formData.append('ad_source', this.addPiAdForm.ad_source);
        axios.post(this.Node_JS_HOST + "/api/v1/web/pi-ads",formData,{
          headers: {'content-type': 'multipart/form-data'},
        })
        .then((res) => {
          if(res){
            this.resetAddPiAdModel();
            this.getPiAds();
            this.snackbarText = 'PI Ad add Successfully';
            this.isShowSnackbar = true;
          }
        })
        .catch((error) => {
          var errorType = error.response.data.error;
          this.errorHandle(error);
          if(errorType === 'Not Acceptable'){
            this.snackbarText = 'Please insert AppName';
            this.isShowSnackbar = true;
          }else{
            this.snackbarText = 'Please insert valid input';
            this.isShowSnackbar = true;
          }
        });
      }
    },
    UpdatePiAdModel(piAdDetail){
      this.updatePiAdsDetail = piAdDetail;
      this.showUpdatePiAdModel = true;
    },
    showDeleteDilog(piAdDetail){
      this.deleteText = 'Are you sure, You want to delete this <b>'+piAdDetail.bannerURL +'</b> Pi Ads.';
      this.deleteId = piAdDetail._id;
      this.showDeleteModel = true;
    },
    deleteAction(actionType,id){
      if(actionType == 'delete'){
           axios.delete(this.Node_JS_HOST + "/api/v1/web/pi-ads/"+id)
            .then((res) => {
              if(res){
                this.getPiAds();
                this.snackbarText = 'PI Ad Deleted';
                this.isShowSnackbar = true;
              }
            })
            .catch((error) => {
              this.errorHandle(error);
              this.snackbarText = 'Something went wrong please try again';
              this.isShowSnackbar = true;
            });
      }
      this.showDeleteModel = false;
      this.deleteId = '';
      this.deleteId = '';
    },
    updatePiAd(){
      this.getPiAds();
      this.showUpdatePiAdModel = false
      this.updatePiAdsDetail = {};
    }
  },
};
</script>
<style scoped>
  ::v-deep th {
    white-space: nowrap!important;
  }
</style>